/* eslint-disable no-useless-computed-key */
const enDic = {
    ["Login"]: "Login",
    ["login"]: "Login",
    ["Password"]: "Password",
    ["Users"]: 'Users',
    ["User"]: 'User',
    ["Companies"]: "Companies",
    ["Devices"]: "Devices",
    ["Roles"]: "Roles",
    ["Messages"]: "Messages",
    ["Graphs"]:"Graphs",
    ["Graphics"]:"Graphics",
    ["Email"]: "Email",
    ["Phone"]: "Phone",
    ["Name"]: "Name",
    ["Role"]: "Role",
    ["Company Name"]: "Company Name",
    ["Company Description"]: "Company Description",
    ["Save"]: "Save",
    ["User is active"]: "User is active",
    ["Company is active"]: "Company is active",
    ["Loading"]: "Loading",
    ["Page not found"]: "Page not found",
    ["Sign in"]: "Sign in",
    ["Sign In"]: "Sign In",
    ["Probes"]: "Indicators",
    ["Setting"]: "Setting",
    ["Settings"]: "Settings",
    ["Notification"]: "Notification",
    ["Title"]: "Title",
    ["Logout"]: "Logout",
    ["ID"]: "ID",
    ["Company ID"]: "Company ID",
    ["Is Active"]: "Is Active",
    ["User Role"]: "User Role",
    ["Created At"]: "Created At",
    ["Comment"]: "Comment",
    ["Allow Create Companies"]: "Allow Create Companies",
    ["Allow Create Devices"]: "Allow Create Devices",
    ["Allow Create Roles"]: "Allow Create Roles",
    ["Allow Create Users"]: "Allow Create Users",
    ["Allow Delete Companies"]: "Allow Delete Companies",
    ["Allow Delete Devices"]: "Allow Delete Devices",
    ["Allow Delete Roles"]: "Allow Delete Roles",
    ["Allow Delete Users"]: "Allow Delete Users",
    ["Allow Edit Companies"]: "Allow Edit Companies",
    ["Allow Edit Devices"]: "Allow Edit Devices",
    ["Allow Edit Roles"]: "Allow Edit Roles",
    ["Allow Edit Users"]: "Allow Edit Users",
    ["Parameter Name"]: "Parameter Name",
    ["Status"]: "Status",
    ["Status of Data Collection"]: "Status of Data Collection",
    ["Date/Time"]: "Date/Time",
    ["Time/Date data updating"]: "Time/Date data updating",
    ["Date/Time data updating"]: "Date/Time data updating",
    ["System Name"]: "System Name",
    ["Latest Value"]: "Latest Value",
    ["Low Critical Limit"]: "Low Critical Limit",
    ["Low Limit"]: "Low Limit",
    ["High Limit"]: "High Limit",
    ["High Critical Limit"]: "High Critical Limit",
    ["Date"]: "Date",
    ["Active"]: "Active",
    ["Connected"]: "Connected",
    ["IP"]: "IP",
    ["Port"]: "Port",
    ["Start time"]: "Start time",
    ["End time"]: "End time",
    ["Rows per page"]: "Rows per page",
    ["of"]: "of",
    ["Add user"]: "Add user",
    ["User Email"]: "User Email",
    ["Email is invalid"]: "Email is invalid",
    ["User Name"]: "User Name",
    ["User Password"]: "User Password",
    ["User Phone"]: "User Phone",
    ["field is required"]: "field is required",
    ["Cancel"]: "Cancel",
    ["Role: "]: "Role: ",
    ["Allow create companies"]: "Allow create companies",
    ["Allow create devices"]: "Allow create devices",
    ["Allow create roles"]: "Allow create roles",
    ["Allow create users"]: "Allow create users",
    ["Allow create access tokens"]: "Allow create access tokens",
    ["Allow edit companies"]: "Allow edit companies",
    ["Allow edit devices"]: "Allow edit devices",
    ["Allow edit roles"]: "Allow edit roles",
    ["Allow edit users"]: "Allow edit users",
    ["Allow delete companies"]: "Allow delete companies",
    ["Allow delete devices"]: "Allow delete devices",
    ["Allow delete roles"]: "Allow delete roles",
    ["Allow delete users"]: "Allow delete users",
    ["Allow delete access tokens"]: "Allow delete access tokens",
    ["Save changes"]: "Save changes",
    ["Delete role"]: "Delete role",
    ["Allow to create companies"]: "Allow to create companies",
    ["Allow to create devices"]: "Allow to create devices",
    ["Allow to create roles"]: "Allow to create roles",
    ["Allow to create users"]: "Allow to create users",
    ["Allow to create access tokens"]: "Allow to create access tokens",
    ["Allow to edit companies"]: "Allow to edit companies",
    ["Allow to edit devices"]: "Allow to edit devices",
    ["Allow to edit roles"]: "Allow to edit roles",
    ["Allow to edit users"]: "Allow to edit users",
    ["Allow to delete companies"]: "Allow to delete companies",
    ["Allow to delete devices"]: "Allow to delete devices",
    ["Allow to delete roles"]: "Allow to delete roles",
    ["Allow to delete users"]: "Allow to delete users",
    ["Allow to delete access tokens"]: "Allow to delete access tokens",
    ["Average Value"]: "Average Value",
    ["Min. Value"]: "Min. Value",
    ["Max. Value"]: "Max. Value",
    ["Hight Limit"]: "Hight Limit",
    ["Hight Critical Limit"]: "Hight Critical Limit",
    ["Add probe"]: "Add Indicator",
    ["Probe Name"]: "Indicator Description",
    ["Add role"]: "Add role",
    ["Forgot Password"]: "Forgot Password",
    ["Email is required"]: "Email is required",
    ["Phone is required"]: "Phone is required",
    ["Submit"]: "Submit",
    ["Forgot password"]: "Forgot password",
    ["Password Change"]: "Password Change",
    ["Old Password"]: "Old Password",
    ["New Password"]: "New Password",
    ["Repeat New Password"]: "Repeat New Password",
    ["Old password is required"]: "Old password is required",
    ["New password is required"]: "New password is required",
    ["Passwords do not match"]: "Passwords do not match",
    ["Back to probes"]: "Back to Indicators",
    ["Previous probe"]: "Previous Indicator",
    ["Next probe"]: "Next Indicator",
    ["Coefficient"]: "Coefficient",
    ["Select probe to compare"]: "Select Indicator to compare",
    ["None"]: "None",
    ["Export Graphic"]: "Export Graphic",
    ["Format"]: "Format",
    ["Export"]: "Export",
    ["action tabs example"]: "action tabs example",
    ["Other"]: "Other",
    ["Delete device"]: "Delete device",
    ["Probe Alert"]: "Indicator Alert",
    ["Device ID"]: "Device ID",
    ["Data Type"]: "Data Type",
    ["Current Temperature"]: "Current Temperature",
    ["Status Updated At"]: "Status Updated At",
    ["Limits"]: "Limits",
    ["Lower Limit"]: "Lower Limit",
    ["Critical Lower Limit"]: "Critical Lower Limit",
    ["Add device"]: "Add device",
    ["Device Name"]: "Device Name",
    ["Device IP"]: "Device IP",
    ["Device Port"]: "Device Port",
    ["Company"]: "Company",
    ["Delete company"]: "Delete company",
    ["Description"]: "Description",
    ["Add company"]: "Add company",
    ["Access Tokens"]: "Access Tokens",
    ["Verification"]: "Verification",
    ["Code"]: "Code",
    ["Confirm"]: "Confirm",
    ["Upload image"]: "Upload image",
    ["For User ID"]: "For User ID",
    ["For User Name"]: "For User Name",
    ["Valid Until"]: "Valid Until",
    ["Add Access Token"]: "Add Access Token",
    ["Create"]: "Create",
    ["Change password"]: "Change password",
    ["Connection Updated At"]: "Connection Updated At",
    ["Connection"]: "Connection",
    ["Delete user"]: "Delete user",
    ["Alarms"]: "Alarms",
    ["Warnings"]: "Warnings",
    ["Warning"]: "Warning",
    ["Errors"]: "Errors",
    ["Alarm level"]: "Alarm level",
    ["Type alarms"]: "Type alarms",
    ["Locale"]: "Locale",
    ["Two-factor authentication"]: "Two-factor authentication",
    ['IP is not valid']: 'IP is not valid',
    ['Port is not valid']: 'Port is not valid',
    ['Filtration']: 'Filtration',
    ['Report']: 'Report',
    ['Phone number']: 'Phone number',
    ["User setting"]: "User setting",
    ["Allow alarms level"]: "Allow alarms level",
    ["Alarm to mail"]: "Alarm to mail",
    ["Alarm to SMS"]: "Alarm to SMS",
    ["Two factor auth"]: "Two factor auth",
    ["Decimals"]: "Decimals",
    ["Limit Decimals"]: "Limit Decimals",
    ["Hight Critical Limit Addr"]: "Hight Critical Limit Memory Address",
    ["Low Critical Limit Addr"]: "Low Critical Limit Memory Address",
    ["Hight Limit Addr"]: "Hight Limit Memory Address",
    ["Low Limit Addr"]: "Low Limit Memory Address",
    ["Value Addr"]: "Value Memory Address",
    ["Baseline Value"]: "Baseline Value",
    ["Is Coil"]: "Is Modbus Coil Value",
    ["Device name is empty"]: "Device name is empty",
    ["Phone number is required"]: "Phone number is required",
    ["Actions"]: "Actions",
    ["Only files with extensions"]: "Only files with extensions",
    ["Dayly report"]: "Dayly report",
    ["Weekly report"]: "Weekly report",
    ["Monthly report"]: "Monthly report",
    ["Download"]: "Download",
    ["Generate"]: "Generate",
    ["Forbidden"]: "Forbidden",
    ["Device"]: "Device",
    ["Confirm delete device"]: "Confirm delete device",
    ["Enter the password to confirm the deletion"]: "Enter the password to confirm the deletion",
    ["ORDERING SYSTEM"]: "ORDERING SYSTEM",
    ["PERSONAL CABINET"]: "PERSONAL CABINET",
    ["Orders"]: "Orders",
    ["Order"]: "Order",
    ["Number"]: "Number",
    ["Sum"]: "Sum",
    ["Cart"]: "Cart",
    ["Price list"]: "Price list",
    ["Minimum order quantity"]: "Minimum order quantity",
    ["Kg"]: "Kg",
    ["m³"]: "m³",
    ["Details"]: "Details",
    ["Discount"]: "Discount",
    ["Quantity"]: "Quantity",
    ["RRP"]: "RRP",
    ["Price"]: "Price",
    ["Availability"]: "Availability",
    ["Category"]: "Category",
    ["Brand"]: "Brand",
    ["Show ordered products"]: "Show ordered products",
    ["Ordered by categories"]: "Ordered by categories",
    ["Order amount"]: "Order amount",
    ["Search"]: "Search",
    ["Confirm the shipment of the Order"]: "Confirm the shipment of the Order",
    ["The order amount must be at least "]: "The order amount must be at least ",
    ["Your comment"]: "Your comment",
    ["Total"]: "Total",
    ["В наявності"]: "In stock",
    ["Закінчується"]: "Ends",
    ["Немає в наявності"]: "Out of stock",
    ["Send"]: "Send",
    ["Clear basket"]: "Clear cart",
    ["Add to basket"]: "Add to cart",
    ["Rewrite basket"]: "Rewrite cart",
    ["Are you sure that you want to rewrite the basket, all other products that were selected will be deleted"]: "'Are you sure that you want to rewrite the cart, all other products that were selected will be deleted'",
    ["Agree"]: "Agree",
    ["Are you sure that you want to clear the basket, all products that were selected will be deleted"]: "Are you sure that you want to clear the cart, all products that were selected will be deleted",
    ["Cart is empty"]: "Cart is empty",
    ['Number of characters']: 'Number of characters',
    ['Wrong email']: 'Wrong email',
    ['Password is required']: 'Password is required',
    ['Company is required']: 'Company is required',
    ['Invalid email or password']: 'Invalid email or password',
    ["Shown"]: "Shown",
    ["The number of selected products is less than the minimum"]: "The number of selected products is less than the minimum",
    ["Information"]: "Information",
    ["Thank you for the order"]: "Thank you for the order",
    ["Our manager will contact you as soon as possible"]: "Our manager will contact you as soon as possible",
    ["Открыт"]: "Open",
    ["Отменён. "]: "Canceled",
    ["В ожидании товаров"]: "Waiting for goods",
    ["В сборке"]: "In assembly",
    ["Доставлен"]: "Delivered",
    ["Завершен"]: "Completed",
    ["Не дозвонились"]: "Did not call back",
    ["Новый"]: "New",
    ["Отправлен"]: "Sent",
    ["Подтвержден"]: "Confirmed",
    ["Собран"]: "Collected",
    ["Action"]: "Action",
    ["User not found"]: "User not found",
    ["Password reset instructions sent."]: "Password reset instructions sent.",
    ["Success!"]: "Success!",

};

export default enDic;
