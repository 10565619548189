import { getData, postData } from "../lib/fetchApi";
import { ORDERS_URL } from "../constants/urls";
import { defaultLocale } from "../config";
import useI18n from "../../../client/src/hooks/useI18n";

const orderService = {

    findAllByUserId: (user, lang) => {
        return getData({ url: ORDERS_URL.FIND_ALL_BY_USER_ID, lang });
    }
}
export default orderService;
