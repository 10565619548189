/* eslint-disable no-useless-computed-key */
const ukDic = {
    ["Login"]: "Логін",
    ["login"]: "Логін",
    ["Password"]: "Пароль",
    ["Users"]: 'Користувачі',
    ["User"]: 'Користувач',
    ["Companies"]: "Компанії",
    ["Devices"]: "Пристрої",
    ["Roles"]: "Ролі",
    ["Messages"]: "Повідомлення",
    ["Graphs"]: "Графіки",
    ["Email"]: "Електронна пошта",
    ["Phone"]: "Телефон",
    ["Name"]: "Найменування",
    ["Role"]: "Роль",
    ["Company Name"]: "Назва компанії",
    ["Company Description"]: "Опис компанії",
    ["Save"]: "Зберегти",
    ["User is active"]: "Користувач активний",
    ["Company is active"]: "Компанія активна",
    ["Loading"]: "Завантаження",
    ["Page not found"]: "Сторінка не знайденна",
    ["Sign in"]: "Увійти",
    ["Sign In"]: "Увійти",
    ["Probes"]: "Показники",
    ["Setting"]: "Налаштування",
    ["Settings"]: "Налаштування",
    ["Notification"]: "Сповіщення",
    ["Title"]: "Назва",
    ["Logout"]: "Вихід",
    ["ID"]: "ID",
    ["Company ID"]: "ID компанії",
    ["Is Active"]: "Активний",
    ["User Role"]: "Роль користувача",
    ["Created At"]: "Створено",
    ["Comment"]: "Коментар",
    ["Allow Create Companies"]: "Дозволити створення компаній",
    ["Allow Create Devices"]: "Дозволити створення пристроїв",
    ["Allow Create Roles"]: "Дозволити створення ролей",
    ["Allow Create Users"]: "Дозволити створення користувачів",
    ["Allow Delete Companies"]: "Дозволити видалення компаній",
    ["Allow Delete Devices"]: "Дозволити видалення пристроїв",
    ["Allow Delete Roles"]: "Дозволити видалення ролей",
    ["Allow Delete Users"]: "Дозволити видалення користувачів",
    ["Allow Edit Companies"]: "Дозволити редагування компаній",
    ["Allow Edit Devices"]: "Дозволити редагування пристроїв",
    ["Allow Edit Roles"]: "Дозволити редагування ролей",
    ["Allow Edit Users"]: "Дозволити редагування користувачів",
    ["Parameter Name"]: "Назва параметра",
    ["Status"]: "Статус",
    ["Status of Data Collection"]: "Статус сбору даних",
    ["Date/Time"]: "Дата/Час",
    ["System Name"]: "Назва системи",
    ["Time/Date data updating"]: "Час/Дата оновлення даних",
    ["Date/Time data updating"]: "Дата/Час оновлення даних",
    ["Latest Value"]: "Останнє значення",
    ["Low Critical Limit"]: "Нижній критичний ліміт",
    ["Low Limit"]: "Нижній ліміт",
    ["High Limit"]: "Верхній ліміт",
    ["High Critical Limit"]: "Верхній критичний ліміт",
    ["Date"]: "Дата",
    ["Active"]: "Активний",
    ["Connected"]: "Підключений",
    ["IP"]: "IP",
    ["Port"]: "Порт",
    ["Start time"]: "Початковий час",
    ["End time"]: "Кінцевий час",
    ["Rows per page"]: "Рядків на сторінці",
    ["of"]: "з",
    ["Add user"]: "Додати користувача",
    ["User Email"]: "Електронна пошта користувача",
    ["Email is invalid"]: "Електронна пошта недійсна",
    ["User Name"]: "Ім'я користувача",
    ["User Password"]: "Пароль користувача",
    ["User Phone"]: "Телефон користувача",
    ["field is required"]: "поле обов'язкове",
    ["Cancel"]: "Скасувати",
    ["Role: "]: "Роль: ",
    ["Allow create companies"]: "Дозволити створення компаній",
    ["Allow create devices"]: "Дозволити створення пристроїв",
    ["Allow create roles"]: "Дозволити створення ролей",
    ["Allow create users"]: "Дозволити створення користувачів",
    ["Allow create access tokens"]: "Дозволити створення токенів доступу",
    ["Allow edit companies"]: "Дозволити редагування компаній",
    ["Allow edit devices"]: "Дозволити редагування пристроїв",
    ["Allow edit roles"]: "Дозволити редагування ролей",
    ["Allow edit users"]: "Дозволити редагування користувачів",
    ["Allow delete companies"]: "Дозволити видалення компаній",
    ["Allow delete devices"]: "Дозволити видалення пристроїв",
    ["Allow delete roles"]: "Дозволити видалення ролей",
    ["Allow delete users"]: "Дозволити видалення користувачів",
    ["Allow delete access tokens"]: "Дозволити видалення токенів доступу",
    ["Save changes"]: "Зберегти зміни",
    ["Delete role"]: "Видалити роль",
    ["Allow to create companies"]: "Дозволити створювати компанії",
    ["Allow to create devices"]: "Дозволити створювати пристрої",
    ["Allow to create roles"]: "Дозволити створювати ролі",
    ["Allow to create users"]: "Дозволити створювати користувачів",
    ["Allow to create access tokens"]: "Дозволити створювати токени доступу",
    ["Allow to edit companies"]: "Дозволити редагувати компанії",
    ["Allow to edit devices"]: "Дозволити редагувати пристрої",
    ["Allow to edit roles"]: "Дозволити редагувати ролі",
    ["Allow to edit users"]: "Дозволити редагувати користувачів",
    ["Allow to delete companies"]: "Дозволити видаляти компанії",
    ["Allow to delete devices"]: "Дозволити видаляти пристрої",
    ["Allow to delete roles"]: "Дозволити видаляти ролі",
    ["Allow to delete users"]: "Дозволити видаляти користувачів",
    ["Allow to delete access tokens"]: "Дозволити видаляти токени доступу",
    ["Average Value"]: "Середнє значення",
    ["Min. Value"]: "Мін. значення",
    ["Max. Value"]: "Макс. значення",
    ["Hight Limit"]: "Верхня межа",
    ["Hight Critical Limit"]: "Верхня критична межа",
    ["Add probe"]: "Додати Показник",
    ["Probe Name"]: "Назва Показника",
    ["Add role"]: "Додати роль",
    ["Forgot Password"]: "Забули пароль",
    ["Email is required"]: "Електронна пошта обов'язкова",
    ["Phone is required"]: "Телефон обов'язковий",
    ["Submit"]: "Надіслати",
    ["Forgot password"]: "Забули пароль",
    ["Password Change"]: "Зміна пароля",
    ["Old Password"]: "Старий пароль",
    ["New Password"]: "Новий пароль",
    ["Repeat New Password"]: "Повторіть новий пароль",
    ["Old password is required"]: "Потрібен старий пароль",
    ["New password is required"]: "Потрібен новий пароль",
    ["Passwords do not match"]: "Паролі не співпадають",
    ["Back to probes"]: "Повернутися до Показників",
    ["Previous probe"]: "Попередній Показник",
    ["Next probe"]: "Наступний Показник",
    ["Coefficient"]: "Коефіцієнт",
    ["Select probes to compare"]: "Виберіть Показники для порівняння",
    ["None"]: "Немає",
    ["Export Graphic"]: "Експорт графіка",
    ["Format"]: "Формат",
    ["Export"]: "Експорт",
    ["action tabs example"]: "приклад дій з вкладками",
    ["Other"]: "Інше",
    ["Delete device"]: "Видалити пристрій",
    ["Probe Alert"]: "Попередження в Показниках",
    ["Device ID"]: "Ідентифікатор пристрою",
    ["Data Type"]: "Тип даних",
    ["Current Temperature"]: "Поточна температура",
    ["Status Updated At"]: "Статус оновлено",
    ["Limits"]: "Ліміти",
    ["Lower Limit"]: "Нижній ліміт",
    ["Critical Lower Limit"]: "Критичний нижній ліміт",
    ["Add device"]: "Додати пристрій",
    ["Device Name"]: "Назва пристрою",
    ["Device IP"]: "IP-адреса пристрою",
    ["Device Port"]: "Порт пристрою",
    ["Company"]: "Компанія",
    ["Delete company"]: "Видалити компанію",
    ["Description"]: "Опис",
    ["Add company"]: "Додати компанію",
    ["Upload/Change image"]: "Завантажити/Змінити зображення",
    ["Access Tokens"]: "Токени доступу",
    ["Verification"]: "Верифікація",
    ["Code"]: "Код",
    ["Confirm"]: "Підтвердити",
    ["Upload image"]: "Завантажити зображення",
    ["Device is active"]: "Пристрій активний",
    ["Timezone"]: "Часовий пояс",
    ["For User ID"]: "Ідентифікатор користувача",
    ["For User Name"]: "Ім'я користувача",
    ["Valid Until"]: "Дійсно до",
    ["Add Access Token"]: "Додати токен доступу",
    ["Create"]: "Створити",
    ["Change password"]: "Змінити пароль",
    ["Connection Updated At"]: "Підключення оновлено",
    ["Connection"]: "Підключення",
    ["Delete user"]: "Видалити користувача",
    ["Alarms"]: "Попередження",
    ["Warnings"]: "Попередження",
    ["Warning"]: "Попередження",
    ["Errors"]: "Помилки",
    ["vs"]: "та",
    ["Alarm level"]: "Рівень попередження",
    ["Type alarms"]: "Тип попереджень",
    ["Locale"]: "Мова",
    ["Two-factor authentication"]: "Двофакторна аутентифікація",
    ['IP is not valid']: 'IP недійсний',
    ['Port is not valid']: 'Порт невалідний',
    ['Filtration']: 'Фільтрація',
    ['Time']: "Час",
    ['Graphics']: "Графік",
    ['Report']: "Звіт",
    ['Phone number']: 'Номер телефону',
    ["User setting"]: "Налаштування користувача",
    ["Allow alarms level"]: "Дозволити рівень сповіщень",
    ["Alarm to mail"]: "Сповіщення на пошту",
    ["Alarm to SMS"]: "Сповіщення на SMS",
    ["Two factor auth"]: "Двофакторна автентифікація",
    ["Decimals"]: "Десяткові",
    ["Limit Decimals"]: "Десяткові лімітив",
    ["Hight Critical Limit Addr"]: "Адреса пам'яті верхнього критичного ліміту",
    ["Low Critical Limit Addr"]: "Адреса пам'яті нижнього критичного ліміту",
    ["Hight Limit Addr"]: "Адреса пам'яті верхнього ліміту",
    ["Low Limit Addr"]: "Адреса пам'яті нижнього ліміту",
    ["Value Addr"]: "Адреса пам'яті значення",
    ["Baseline Value"]: "Базове значення",
    ["Is Coil"]: "Це Modbus Coils",
    ["Device name is empty"]: "Назва пристрою порожня",
    ["Phone number is required"]: "Телефонний номер обов'язковий",
    ["Actions"]: "Дії",
    ["Only files with extensions"]: "Дозволені тільки файли з розширеннями",
    ["Dayly report"]: "Щоденний звіт",
    ["Weekly report"]: "Тижневий звіт",
    ["Monthly report"]: "Місячний звіт",
    ["Download"]: "Завантажити",
    ["Generate"]: "Створити",
    ["Forbidden"]: "Заборонено",
    ["Device"]: "Пристрій",
    ["Confirm delete device"]: "Підтвердіть видалення пристрою",
    ["Enter the password to confirm the deletion"]: "Введіть пароль для підтвердження видалення",
    ["ORDERING SYSTEM"]: "СИСТЕМА ЗАМОВЛЕННЯ",
    ["PERSONAL CABINET"]: "ОСОБИСТИЙ КАБІНЕТ",
    ["Orders"]: "Замовлення",
    ["Order"]: "Замовлення",
    ["Number"]: "Номер",
    ["Sum"]: "Сума",
    ["Cart"]: "Кошик",
    ["Price list"]: "Прайс-лист",
    ["Minimum order quantity"]: "Мінімальна кількість замовлення",
    ["Kg"]: "кг",
    ["m³"]: "м³",
    ["Details"]: "Детальніше",
    ["Discount"]: "Знижка",
    ["Quantity"]: "Кількість",
    ["RRP"]: "РРЦ",
    ["Image"]: "Зображення",
    ["Price"]: "Ціна",
    ["Availability"]: "Наявність",
    ["Category"]: "Категорія",
    ["Brand"]: "Бренд",
    ["Show ordered products"]: "Показати замовлені товари",
    ["Ordered by categories"]: "Замовлено за категоріями",
    ["Order amount"]: "Сума замовлення",
    ["Search"]: "Пошук",
    ["Confirm the shipment of the Order"]: "Підтвердіть відправку Замовлення",
    ["The order amount must be at least "]: "Сума замовлення має бути не менше ",
    ["Your comment"]: "Ваш коментар",
    ["Total"]: "Всього",
    ["В наявності"]: "В наявності",
    ["Закінчується"]: "Закінчується",
    ["Немає в наявності"]: "Немає в наявності",
    ["Send"]: "Відправити",
    ["Clear basket"]: "Очистити кошик",
    ["Add to basket"]: "Додати в кошик",
    ["Rewrite basket"]: "Переписати кошик",
    ["Are you sure that you want to rewrite the basket, all other products that were selected will be deleted"]: "Ви впевнені, що хочете переписати кошик, всі інші вибрані товари будуть видалені",
    ["Agree"]: "Погоджуюсь",
    ["Are you sure that you want to clear the basket, all products that were selected will be deleted"]: "Ви впевнені, що хочете очистити кошик, всі вибрані товари будуть видалені",
    ["Cart is empty"]: "Кошик порожній",
    ["Number of characters"]: "Кількість символів",
    ['Wrong email']: 'Неправильна електронна пошта',
    ['Password is required']: 'Пароль обов\'язковий',
    ['Company is required']: 'Компанія обов\'язкова',
    ['Invalid email or password']: 'Невірна адреса електронної пошти або пароль',
    ["Shown"]: "Показано",
    ["The number of selected products is less than the minimum"]: "Кількість виділених товарів менша за мінімальну",
    ["Information"]: "Інформація",
    ["Thank you for the order"]: "Дякуємо за замовлення",
    ["Our manager will contact you as soon as possible"]: "Наш менеджер зв'яжеться з вами найближчим часом",
    ["Открыт"]: "Відкритий",
    ["Отменён. "]: "Скасований",
    ["В ожидании товаров"]: "Очікування товарів",
    ["В сборке"]: "У складанні",
    ["Доставлен"]: "Доставлено",
    ["Завершен"]: "Завершено",
    ["Не дозвонились"]: "Не додзвонились",
    ["Новый"]: "Новий",
    ["Отправлен"]: "Відправлено",
    ["Подтвержден"]: "Підтверджено",
    ["Собран"]: "Зібрано",
    ["Action"]: "Дія",
    ["Order system"]: "Система замовлення",
    ["User not found"]: "Користувача не знайдено",
    ["Password reset instructions sent."]: "Інструкції по скиданню пароля надіслані.",
    ["Success!"]: "Успіх!",
    ["Password changed successfully!"]: "Пароль успішно змінено!",
};

export default ukDic;
