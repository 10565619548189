
import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { 
    Avatar, 
    Button, 
    CssBaseline, 
    TextField, 
    Box, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Typography, 
    Container 
} from '@mui/material';
import Link from '@mui/material/Link';
import SelectLanguage from '../../custom/SelectLanguage/SelectLanguage';
import { useNavigate } from 'react-router-dom';
import useI18n from '../../../hooks/useI18n';
import publicService from '../../../api/public';


export default function ForgotPassword() {
    const { l, language } = useI18n();
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = React.useState({
        email: true,
        phone: true
    });
    const [error, setError] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');
        const phone = data.get('phone');
        const forgotPasswordErrors = {
            email: !!email,
            phone: !!phone
        };

        setFormErrors(forgotPasswordErrors);

        try {
            const forgotPassword = await publicService.forgotPasswordAsync({ data: { email, phone }, lang: language });
            setOpenDialog(true);
        } catch (error) {
            setError(l(error.message));
        }
    };

    const handleCancel = (event) => {
        event.preventDefault();
        navigate('/login');
    }   

    const handleDialogOnClose = () => {
        setOpenDialog(false);
        navigate('/login');
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar style={{ backgroundColor: '#F0B21C'}} sx={{ m: 1 }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {l('Forgot Password')}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={l("Email")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={!formErrors.email}
                        helperText={!formErrors.email && l('Email is required')}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="phone"
                        label={l("Phone")}
                        type="phone"
                        id="phone"
                        autoComplete="phone"
                        error={!formErrors.phone}
                        helperText={!formErrors.phone && l('Phone is required')}
                    />

                    <Typography color={'red'}>
                        {error ? error : ''}
                    </Typography>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size='large'
                        style={{ backgroundColor: '#F0B21C'}}
                        sx={{ mt: 2, mb: 2 }}
                    >
                        {l('Submit')}
                    </Button>
                    <Box display={'flex'} sx={{justifyContent: 'space-between', alignItems: 'end'}}>
                        <Link href="#" variant="body2" onClick={handleCancel}>
                            {l('Cancel')}
                        </Link>
                        <SelectLanguage variant={'standard'} />
                    </Box>
                </Box>
            </Box>
            <Dialog open={openDialog} onClose={handleDialogOnClose}>
                <DialogTitle>{l("Success!")}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{l("Password reset instructions sent.")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogOnClose} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
