import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ListItemIcon from '@mui/material/ListItemIcon';
import PasswordIcon from '@mui/icons-material/Password';

import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import SelectLanguage from '../../custom/SelectLanguage/SelectLanguage';
import Box from '@mui/material/Box';
import { Avatar } from '@mui/material';
import { userSettingPage, passwordChangePage, priceListPage } from '../../../constants/pages';

import { useNavigate } from 'react-router-dom';
import useI18n from '../../../hooks/useI18n';

import useLogin from '../../../hooks/useLogin';

export default function MenuToolbar(prop) {
    const { l, language } = useI18n();
    const { handleDrawer } = prop;
    const { open } = prop;

    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { logout } = useLogin();
    const login = sessionStorage.getItem('user');
    const navigate = useNavigate()


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutUser = () => {
        logout();
        handleClose();
    }

    const hendleSettings = () => {
        handleClose();
        navigate(userSettingPage)
    }

    const handlePasswordChange = () => {
        navigate(passwordChangePage)
    }

    return (
        <Toolbar style={{ paddingRight: 0, backgroundColor: '#2c3335' }}>
            <Box sx={{ display: 'flex', flexGrow: '1', alignItems: 'center' }}>

                <IconButton color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawer}
                    edge="start"
                    sx={{
                        height: '40px',
                        marginRight: 2,
                    }}>
                    {open === false ? <MenuIcon /> : <CloseIcon />}
                </IconButton>
                <img
                    onClick={() => { navigate(priceListPage) }}
                    src="./serpsample.jpg"
                    alt="Logo"
                    style={{ height: 50, cursor: 'pointer' }} />
                {/* <Typography sx={{ fontFamily: 'Montserrat, sans-serif',
                fontSize: '25px',
                marginLeft: '20px',

                }}>{l('PERSONAL CABINET')}</Typography> */}

                {/* <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
                {l(process.env.REACT_APP_COPMANY_NAME)}
            </Typography> */}
                {/* <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
                {selectedCompany || l('Select Company')}
            </Typography> */}
            </Box>

            {auth && (
                <div>
                    <Typography variant="h6" noWrap component="span" sx={{ mr: 1 }}>
                        {login}
                    </Typography>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        edge="end"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={hendleSettings}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            {l('Settings')}
                        </MenuItem>
                        <MenuItem onClick={handlePasswordChange}>
                            <ListItemIcon>
                                <PasswordIcon fontSize="small" />
                            </ListItemIcon>
                            {l('Change password')}
                        </MenuItem>
                        <MenuItem onClick={logoutUser}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            {l('Logout')}
                        </MenuItem>
                    </Menu>
                </div>
            )}
            <Divider sx={{ background: 'white', marginLeft: 3 }} orientation="vertical" variant="middle" flexItem />
            <SelectLanguage color={'white'} sx={{ m: 1, minWidth: 50, padding: 0 }} />
        </Toolbar>
    );
}